<template>
  <section>
      <!-- Loading -->

      <template >
        <!-- Loading -->
        <div v-if="!destinationsLoaded" class="loading apollo">Loading...</div>

        <div v-else-if="destinations && destinations.length > 0">
          <v-card-title>
            Client Destinations
            <v-spacer></v-spacer>
            <v-select
              class="filter-input mr-2"
              single-line
              v-if="isAdmin"
              hide-details
              :items="
                !customers
                  ? [
                      {
                        text: 'All Clients',
                        value: null
                      }
                    ]
                  : [
                      {
                        text: 'All Clients',
                        value: null
                      }
                    ].concat(
                      customers.map(item => {
                        return {
                          text: item.name,
                          value: parseInt(item.id)
                        };
                      })
                    )
              "
              v-model="customer"
              label="All Clients"
            ></v-select>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              class="filter-input"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="destinations"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
            :items-per-page="30"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      >Add Destination</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="editedItem.alias"
                                hide-details="auto"
                                :rules="requiredRules"
                                label="Alias"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              class="custom-error-handler-top-item mt-4"
                            >
                              <VueGooglePlaces
                                :api-key="apiKey"
                                ref="googleSearch"
                                country="usa"
                                class="google-place-search"
                                placeholder="Address"
                                v-model="editedItem.address"
                                @placechanged="onPlaceChanged"
                              />
                              <v-text-field
                                v-model="editedItem.address"
                                hide-details="auto"
                                :rules="requiredRules"
                                class="custom-error-handler-input"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="editedItem.suite"
                                label="Suite"
                                outlined
                                class="mt-2"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="editedItem.comment"
                                label="Comment"
                                outlined
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" v-if="isAdmin">
                              <v-select
                                hide-details="auto"
                                :rules="requiredRules"
                                :items="
                                  !customers
                                    ? [
                                        {
                                          text: 'None',
                                          value: null
                                        }
                                      ]
                                    : [
                                        {
                                          text: 'None',
                                          value: null
                                        }
                                      ].concat(
                                        customers.map(item => {
                                          return {
                                            text: item.name,
                                            value: parseInt(item.id)
                                          };
                                        })
                                      )
                                "
                                v-model="editedItem.customer"
                                label="Client"
                                outlined
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="save()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.alias="{ item }">{{ item.alias }}</template>
            <template v-slot:item.address="{ item }">
              {{ item.address }}
            </template>
            <template v-slot:item.suite="{ item }">
              {{ item.suite }}
            </template>
            <template v-slot:item.comment="{ item }">
              {{ item.comment }}
            </template>
            <template v-slot:item.customer="{ item }">
              {{ item.customer ? item.customer.name : "-" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>View/Edit Destination</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="deleteItem(item)"
                    v-if="isAdmin || isClientAdmin"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Delete Destination</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
          </v-data-table>
        </div>
        <!-- No result -->
        <div v-else class="no-result apollo">No results</div>
      </template>
    </ApolloQuery>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

import { DateTime } from "luxon";

export default {
  data: () => ({
    dialog: false,
    //
    search: "",
    customer: null,
    // headers:
    editedIndex: -1,
    editedItem: {},
    defaultItem: { address: null },
    //
    apiKey: "AIzaSyBFwsSWdZdUYzlomq7JyucZdEx8hTEjRnU",
    //
    valid: true,
    //  hide-details="auto"
    // :rules="requiredRules"
    requiredRules: [v => !!v || "Field is required"],
    //
    customers: [],
    destinations: [],
    destinationsLoaded: false
  }),
  apollo: {
    Destinations: {
      prefetch: true,
      query: require("@/graphql/Destinations.gql"),
      fetchPolicy: "cache-and-network",
      pollInterval: 15000,
      update: data => data,
      result(data) {
        if (!data.data) return;
        try {
          const { parse, stringify } = require("flatted/cjs");
          localStorage.setItem(
            "destinations",
            stringify(data.data.destinations)
          );
        } catch (err) {
          console.log("error with cache", err);
        }
        this.destinations = data.data.destinations;
        this.customers = data.data.customers;
        this.destinationsLoaded = true;
      }
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Destination" : "Edit Destination";
    },
    headers() {
      return [
        {
          text: "Alias",
          align: "start",
          sortable: false,
          value: "alias",
          filterable: false
        },
        {
          text: "Address",
          sortable: false,
          value: "address",
          filterable: false
        },
        { text: "Suite", value: "suite", filterable: false },
        { text: "Comment", value: "comment", filterable: false },
        {
          text: "Client",
          value: "customer",
          roles: ["Admin"],
          filterable: false
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filter: this.searchFilter
        }
      ].filter(item => !item.roles || item.roles.includes(this.user.role.name));
    },
    ...mapGetters([
      "user",
      "strapi",
      "isAdmin",
      "isClientAdmin",
      "isClientUser",
      "isDriver"
    ])
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    try {
      const { parse, stringify } = require("flatted/cjs");
      let destinationsCache = parse(localStorage.getItem("destinations"));
      if (destinationsCache) {
        this.destinations = destinationsCache;
        this.destinationsLoaded = true;
      }
    } catch (err) {
      console.log("error with cache", err);
    }
  },

  methods: {
    searchFilter(_value, _search, item) {
      if (this.customer) {
        if (!item.customer || parseInt(item.customer.id) != this.customer)
          return false;
      }

      var searchWord = this.search.toLowerCase();
      if (item.alias && item.alias.toLowerCase().includes(searchWord))
        return true;
      if (item.comment && item.comment.toLowerCase().includes(searchWord))
        return true;
      if (item.suite && item.suite.toLowerCase().includes(searchWord))
        return true;
      return false;
    },
    onPlaceChanged(place) {
      this.editedItem.latitude = place.latitude;
      this.editedItem.longitude = place.longitude;
    },
    initialize() {
      if (
        this.user.role.name == "ClientUser" ||
        this.user.role.name == "ClientAdmin"
      ) {
        this.defaultItem.customer = this.user.customer
          ? parseInt(this.user.customer.id)
          : undefined;
      }
      // Object.assign(this.editedItem, this.defaultItem);

      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    },

    editItem(item) {
      this.editedIndex = this.destinations.findIndex(i => i.id == item.id);
      // Object.assign(this.editedItem, item);

      this.editedItem = this.lodash.cloneDeep(item);
      this.editedItem.customer = item.customer
        ? parseInt(item.customer.id)
        : undefined;

      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.googleSearch.textValue = item.address;
        this.$refs.googleSearch.element.value = item.address;
      });
    },

    async deleteItem(item) {
      const index = this.destinations.findIndex(i => i.id == item.id);
      var result = confirm("Are you sure you want to delete this item?");
      if (result) {
        await this.strapi.deleteEntry("destinations", item.id);
        this.destinations.splice(index, 1);
      }
    },
    tConvert(time) {
      if (!time) return "Time not selected";
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },

    close() {
      this.dialog = false;
      // Object.assign(this.editedItem, this.defaultItem);

      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.$nextTick(() => {
        this.$refs.googleSearch.textValue = null;
        this.$refs.googleSearch.element.value = null;
      });
      // console.log(this.editedItem);
    },

    async save() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if (this.editedIndex > -1) {
          var updatedItem = await this.strapi.updateEntry(
            "destinations",
            parseInt(this.editedItem.id),
            this.editedItem
          );
          Object.assign(this.destinations[this.editedIndex], updatedItem);
        } else {
          if (
            this.user.role.name == "ClientUser" ||
            this.user.role.name == "ClientAdmin"
          ) {
            this.editedItem.customer = this.user.customer
              ? parseInt(this.user.customer.id)
              : undefined;
          }

          // notification time
          var time = DateTime.local()
            .toJSON()
            .slice(11, 16);
          this.editedItem.timeNotification = this.tConvert(time);

          var newItem = await this.strapi.createEntry(
            "destinations",
            this.editedItem
          );
          this.destinations.push(newItem);
        }
        this.close();
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
